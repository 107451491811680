<template>
  <div>
    <vx-card>
      <vs-row>
        <vs-col vs-w="5" class="pr-5">
          <div>
            <label>Label</label>
            <vs-input
              class="w-full"
              v-validate="'required|max:100'"
              size="large"
              maxLength="100"
              placeholder="Label*"
              v-model="label"
              name="label"
            />
            <span class="text-danger text-sm" v-show="errors.has('label')">{{
              errors.first("label")
            }}</span>
          </div>
          <div class="mt-5 flex">
            <vs-radio v-model="type" vs-name="type" vs-value="text" class="custom-radio flex flex-row w-full align-items-center">
              <label class="w-full m-0 p-0 ml-4">Free text entry</label>
            </vs-radio>
          </div>
          <div>
            <vs-radio v-model="type" vs-name="type" vs-value="select" class="custom-radio flex flex-row">
              <label class="w-full m-0 p-0 ml-4">Drop down list</label>
            </vs-radio>
          </div>
          <div>
            <vs-radio v-model="type" vs-name="type" vs-value="date" class="custom-radio flex flex-row ">
              <label class="w-full m-0 p-0 ml-4">Date selector</label>
            </vs-radio>
          </div>
          <div class="mt-10" v-if="type === 'select'">
            <h4 class="mb-1">Add dropdown data</h4>
            <template v-for="(value, key) in presetData">
              <vs-row :key="key">
                <vs-col vs-w="10" class="pr-5">
                  <div>
                    <vs-input
                      class="w-full"
                      v-validate="'required|max:100'"
                      size="large"
                      maxLength="100"
                      label-placeholder="Value*"
                      v-model="value.value"
                      :name="`value[${key}]`"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has(`value[${key}]`)"
                      >{{
                        changeMessage(
                          errors.first(`value[${key}]`),
                          `value[${key}]`,
                          "value"
                        )
                      }}</span
                    >
                  </div>
                </vs-col>
                <vs-col vs-w="2" class="p-5">
                  <vs-button v-round color="danger" @click="prepareDelete(key)"
                    >Delete</vs-button
                  >
                </vs-col>
              </vs-row>
            </template>
            <div class="mt-4">
              <vs-button v-round @click="addDropDownField">Add more</vs-button>
            </div>
          </div>
        </vs-col>
        <vs-col vs-w="5">
          <div v-if="type === 'text'">
            <label class="w-full">{{ label ? label : 'Example text field'}}</label>
            <vs-input
                class="w-full mt-0"
                size="large"
                name="Example"
                v-model="exampleFieldsPlaceholders.freeText"
              />
          </div>
          <div v-else-if="type === 'select'">
            <label class="w-full">{{ label ? label : 'Example dropdown list'}}</label>
              <v-select
                :class="'connector-select const-h p-0'"
                :options="presetData.map(e => e.value)"
                name="Drop down list"
                :clearable="false"
                v-model="exampleFieldsPlaceholders.dropDown"
              ></v-select>
          </div>
          <div v-else>
            <label class="w-full">{{ label ? label : 'Example date selector'}}</label>
              <date-picker
                format="D-M-YYYY"
                valuetype="format"
                name="Date selector"
                input-class="date-picker-input"
                lang="jp"
                v-model="exampleFieldsPlaceholders.date"
              ></date-picker>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <div class="flex flex-wrap mt-5 pr-5">
      <vs-button v-round class="ml-auto" @click="prepareSubmit" size="large"
        >Save changes</vs-button
      >
      <vs-button
        class="ml-10 danger"
        color="danger"
        @click="$router.go(-1)"
        size="large"
        v-round
        >Cancel</vs-button
      >
    </div>
    <vs-row>
      <vs-col vs-w="5" class=""> </vs-col>
    </vs-row>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DatePicker from "vue2-datepicker";

Vue.component("v-select", vSelect);

export default {
  components: { DatePicker },
  data() {
    return {
      label: "",
      type: "text",
      types: ["text", "date", "select"],
      presetData: [],
      preset: {
        value: ""
      },
      serverUrl: `${process.env.VUE_APP_API_URL}uploads/sample/`,
      exampleOptions: ["Advertising", "Sales Deposit", "Rent", "Holding Deposit", "Bond", "Utilities"],
      exampleFieldsPlaceholders: {
        freeText: '',
        dropDown: '',
        date: '',
      }
    };
  },
  methods: {
    ...mapActions("customField", [
      "createCustomField",
      "fetchCustomFieldById",
      "updateCustomFieldById"
    ]),
    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },
    addDropDownField() {
      this.presetData.push(JSON.parse(JSON.stringify(this.preset)));
    },
    prepareDelete(key) {
      this.presetData.splice(key, 1);
    },
    prepareSubmit() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          let data = {
            partnerId: this.partnerId,
            label: this.label,
            type: this.type,
            presetData: JSON.stringify(this.presetData.map(i => i.value))
          };
          if (this.$route.params.id) this.updateCustomField(data);
          else this.saveCustomField(data);
        }
      });
    },
    async saveCustomField(data) {
      await this.createCustomField(data)
        .then(c => {
          this.$vs.loading.close();

          if (c.status === 422)
            this.showMessage("Error", err.data.message, "danger", "icon-times");
          else {
            this.showMessage(
              "Success",
              "Custom field created successfully.",
              "success",
              "icon-check-circle"
            );
            this.$router.push({ name: "partner-pages" });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          this.showMessage(
            "Error",
            err.response.data.message,
            "danger",
            "icon-times"
          );
        });
    },
    async updateCustomField(data) {
      let obj = {
        data: data,
        id: this.$route.params.id
      };
      await this.updateCustomFieldById(obj)
        .then(c => {
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Custom field updated successfully.",
            "success",
            "icon-check-circle"
          );
          this.$router.push({ name: "partner-pages" });
        })
        .catch(err => {
          this.$vs.loading.close();

          this.showMessage("Error", err.message, "danger", "icon-times");
        });
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon
      });
    },
    async getCustomField(id) {
      await this.fetchCustomFieldById(id)
        .then(result => {
          this.label = result.data.data.label;
          this.type = result.data.data.type;
          this.presetData = result.data.data.presetData.map(i => {
            return { value: i };
          });
        })
        .catch(Err => {} );
    }
  },
  mounted() {
    if (this.$route.params.id) this.getCustomField(this.$route.params.id);
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    }
  }
};
</script>
